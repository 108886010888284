<template>
  <v-card>
    <v-card-title class="text-h6">{{ $t("labels.chart") }}</v-card-title>

    <v-card-text>
      <canvas id="chart-el" width="100%" height="35"></canvas>
    </v-card-text>
  </v-card>
</template>

<script>
import { httpClient } from "@/libs/http";
import { Chart } from "chart.js";

export default {
  name: "ReportChart",
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    dates: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    items: [],
  }),
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      httpClient
        .post("/goods-report-chart", {
          id_goods: this.item.id,
          id_warehouse: this.item.id_warehouse,
          data_date: this.dates,
        })
        .then(({ data }) => {
          this.items = [...data];
          setTimeout(() => {
            this.setChart();
          }, 500);
        });
    },
    setChart() {
      const ctx = document.getElementById("chart-el").getContext("2d");
      const labels = [];
      const totalInOrder = [];
      const totalHandover = [];
      for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];
        labels.push(this.formatDateTime(item.data_date, "DD/MM/YYYY"));
        totalInOrder.push(+item.total_in_order);
        totalHandover.push(+item.total_handover);
      }

      const self = this;
      new Chart(ctx, {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: this.$t("labels.total_in_order"),
              data: totalInOrder,
              backgroundColor: ["rgba(255, 99, 132, 0.2)"],
              borderColor: ["rgba(255, 99, 132, 1)"],
              borderWidth: 1,
            },
            {
              label: this.$t("labels.total_handover"),
              data: totalHandover,
              backgroundColor: ["rgba(0, 99, 132, 0.2)"],
              borderColor: ["rgba(0, 99, 132, 1)"],
              borderWidth: 1,
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: function (label) {
                    return label > 999 ? self.formatNumber(label) : label;
                  },
                },
              },
            ],
          },
        },
      });
    },
  },
};
</script>
